@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicBook.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicDemi.ttf') format('truetype');
  font-weight: 600; /* Semi-Bold */
  font-style: normal;
}

@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicExtraBold.ttf') format('truetype');
  font-weight: 800; /* Extra-Bold */
  font-style: normal;
}

@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicHeavy.ttf') format('truetype');
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicLight.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'FuturaCyrillic';
  src: url('../assets/fonts/FuturaCyrillicMedium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}
